import React from "react"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhyUuoniBGTablet from "../images/artwork/WhyUuoni-BG-Tablet_v1.svg"
import WhyUuoniBGElement from "../images/artwork/WhyUuoni-BGElement_v2.svg"
import WhyUuoniBody1 from "../images/artwork/WhyUuoni-Body1_v1.svg"
import WhyUuoniBody2_1 from "../images/artwork/WhyUuoni-Body2-1_v2.svg"
import WhyUuoniBody2_2 from "../images/artwork/WhyUuoni-Body2-2_v1.svg"
import WhyUuoniBody2_3 from "../images/artwork/WhyUuoni-Body2-3_v1.svg"
import WhyUuoniBody3 from "../images/artwork/WhyUuoni-Body3_v2.svg"
import WhyUuoniHero from "../images/artwork/WhyUuoni-Hero_v3.svg"

const WhyPage = () => (
  <Layout>
    <SEO title="Why" />
    <Containers.PageContainer>
      <Containers.SectionContainer>
        <BGImg src={WhyUuoniBGElement} />
        <BGImgTablet src={WhyUuoniBGTablet} />
        <SectionWrapper>
          <ContentWrapper column>
            <HeroImg src={WhyUuoniHero} alt="Success" />
            <HeroContent>
              <h2>
                Uuoni was created with a clear purpose in our minds, that is You
              </h2>
              <p>
                Digital trends and transformations are moving faster than
                before. To stay on top of the game, insurance agents & brokers
                are expected to be multi-hyphenated to ascribe more value.
              </p>
              <p>
                We see the value in how agents and brokers alike can be
                influential in this industry, so we endeavour to future-proof
                your digital transformational journey.
              </p>
            </HeroContent>
          </ContentWrapper>
          <SectionContainer>
            <ContentWrapper>
              <S1Content>
                Uuoni is your empowered leap in to the digital world of
                Insurance
              </S1Content>
              <S1Img src={WhyUuoniBody1} alt="Confidence" />
            </ContentWrapper>
            <S2Container>
              <ContentWrapper>
                <S2Img1 src={WhyUuoniBody2_1} alt="Research" />
                <S2Content>
                  With similar goals, we focus on facilitating the process of
                  nurturing and cultivating relationships
                </S2Content>
              </ContentWrapper>
              <ContentWrapper>
                <S2Img2 src={WhyUuoniBody2_2} alt="Development" />
                <S2Img3 src={WhyUuoniBody2_3} alt="Ideation" />
              </ContentWrapper>
            </S2Container>
            <S3Content>
              And seek to enhance your operational workflows by limiting
              inefficiencies
            </S3Content>
            <S3Img src={WhyUuoniBody3} alt="Support" />
          </SectionContainer>
        </SectionWrapper>
      </Containers.SectionContainer>
      <CTAContainer>
        <CTAContent>
          <h2 style={{ marginBottom: "1.875rem" }}>
            Let’s take the first step towards digital adoption together.
          </h2>
          <Buttons.ButtonLink to="/contact">
            Request Demo Now
          </Buttons.ButtonLink>
        </CTAContent>
      </CTAContainer>
    </Containers.PageContainer>
  </Layout>
)

export default WhyPage

const SectionWrapper = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 4.5rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: ${props => (props.column ? "column" : "row")};
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const BGImg = styled.img`
  position: absolute;
  top: 19%;
  z-index: -100;
  transform: scale(1.115);

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media screen and (min-width: 1025px) {
  }
`

const BGImgTablet = styled.img`
  position: absolute;
  top: 29%;
  z-index: -100;
  transform: scale(1.11);

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const HeroImg = styled.img`
  @media screen and (max-width: 767px) {
    order: 2;
    transform: scale(1.4);
    margin-top: 1rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    order: 2;
    width: 100%;
    margin-top: -7%;
  }

  @media screen and (min-width: 1025px) {
    width: 53%;
    transform: scale(1.135) translate(-5%, 0%);
  }
`

const S1Img = styled.img`
  @media screen and (max-width: 767px) {
    transform: scale(1.1) translateX(1%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
    transform: scale(1.3) translate(13%, -5%);
  }

  @media screen and (min-width: 1025px) {
    width: 55%;
    transform: scale(1.03) translate(10%, 22%);
  }
`

const S2Img1 = styled.img`
  @media screen and (min-width: 1025px) {
    width: 50%;
    transform: translate(-11%, -19%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    order: 1;
    width: 50%;
    margin-left: -4%;
    transform: scale(1.2) translate(5%, -25%);
  }

  @media screen and (max-width: 767px) {
    order: 2;
    transform: scale(1.1) translateX(-3%);
  }
`

const S2Img2 = styled.img`
  @media screen and (min-width: 1025px) {
    width: 49%;
    transform: translate(15%, -80%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
    transform: scale(1.1) translate(15%, -65%);
  }

  @media screen and (max-width: 767px) {
    transform: translate(25%, -60%);
  }
`

const S2Img3 = styled.img`
  @media screen and (min-width: 1025px) {
    width: 49%;
    transform: translate(-12%, -20%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
    transform: scale(1.15) translate(1%, -14%);
  }

  @media screen and (max-width: 767px) {
    transform: scale(1.15) translateY(-50%);
  }
`

const S3Img = styled.img`
  @media screen and (max-width: 767px) {
    transform: scale(1.1) translateX(2.5%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: -5%;
    transform: translateY(-5%);
  }

  @media screen and (min-width: 1025px) {
    transform: scale(0.83) translate(3%, -9%);
  }
`

const HeroContent = styled.div`
  @media screen and (max-width: 1024px) {
    order: 1;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    text-align: center;
  }
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 15%;
  }
`

const S1Content = styled.h2`
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 42%;
    margin-top: 2%;
  }

  @media screen and (min-width: 1025px) {
    width: 50%;
    align-self: flex-end;
    margin-bottom: 9%;
  }
`

const S2Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (max-width: 767px) {
    margin-bottom: -45%;
  }
`

const S2Content = styled.h2`
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    text-align: right;
    width: 50%;
    order: 2;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 8%;
  }

  @media screen and (min-width: 1025px) {
    width: 55%;
    margin-left: 7%;
    margin-top: 23%;
  }

  @media screen and (max-width: 767px) {
    margin-left: auto
    width: 80%;
    order: 1;
    text-align: right;
  }
`

const S3Content = styled.h2`
  width: 55%;

  @media screen and (min-width: 1025px) {
    transform: translateY(-15%);
    margin-bottom: 9%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 60%;
    margin-top: 3%;
    margin-bottom: 2%;
    text-align: left;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const CTAContainer = styled(Containers.CTAContainer)`
  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 20%;
  }

  @media screen and (max-width: 767px) {
  }
`

const CTAContent = styled.div`
  margin: auto;

  @media screen and (min-width: 768px) {
    text-align: center;
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
  }
`
